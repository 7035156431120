import './App.css';
import { Header, Footer, Blog, Posts, Post } from './components';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sticky from './components/stickyelements/Sticky';

import {
  Home,
  About,
  Contact,
  VR,
  VRL,
  MAD,
  MR,
  WDD,
  RI,
  Php,
  Laravel,
  CodeIngenator,
  Python,
  Node,
  Android,
  Angular,
  Reactjs,
  Vue,
  Mean,
  Mern,
  Magento,
  WooCommerce,
  Shopify,
  IOS,
  ReactNative,
  Hybrid,
  Flutter,
  Ionic,
  Swift,
  Worldpress,
  Drupal,
  CustomUnrealEngine,
  UnityAppDevelopment,
  Project1,
  Project2,
  Projects,
  AR,
  Technology,
  Privacy,
  Terms,
  Ai,
  Immersive,
  Digitialtwinning,
  DinoWorld,
} from './Pages';
import Message from './Pages/Message/index';
import ELearning from './Pages/E-learning/eLearning';
import AIDevelopment from './Pages/AIDevelopment';
import Casestudy from './Pages/ChatBotCaseStudy/Casestudy';
import Platform from './Pages/Platform/PlatformDev';
import ImmersiveRoom from './Pages/ImmersiveRoom/ImmersiveRoomDev';
import ScrollToTop from './components/ScrollToTop';
import Blogs from './Pages/Blogs/Blogs';
import Event from './Pages/Event/Event';
import Examwizz from './Pages/Examwizz/Examwizz';
import InsightXR from './Pages/InsightXR/InsightXR';
import ImmCase from './components/ImmersiveCase';
import AllCasePage from './components/AllCaseStudy/CaseStudyPage';
import NhsCase from './components/NHScaseStudy/NhsCaseStudy';
import Loader from './components/Loader/SiteLoader';
import JobsMxr from './Pages/Jobs/Jobs';
import JobDetail from './components/JobsCompo/JobDetails';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    const timer = setTimeout(handleLoad, 1500);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   const handleLoad = () => setLoading(false);
  //   window.addEventListener('load', handleLoad);
  //   return () => window.removeEventListener('load', handleLoad);
  // }, []);

  const pathname = window.location.pathname;

  // Extract the page name (last segment of the pathname)
  // const pageName = pathname.split('/').filter(Boolean).pop();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pathname === '/dino-world' ? '' : <Header />}
          <ScrollToTop />
          <Sticky />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/blog' element={<Blog />}>
              <Route index element={<Posts />} />
              <Route path=':postSlug' element={<Post />} />
            </Route>
            <Route path='/vr-360' element={<VR />} />
            <Route path='/augmented-reality' element={<AR />} />
            <Route path='/virtual-reality' element={<VRL />} />
            <Route path='/mixed-reality' element={<MR />} />
            <Route path='/mobile-app-development' element={<MAD />} />
            <Route path='/web-design-and-development' element={<WDD />} />
            <Route path='/research-and-innovation' element={<RI />} />
            <Route path='/php' element={<Php />} />
            <Route path='/laravel' element={<Laravel />} />
            <Route path='/code' element={<CodeIngenator />} />
            <Route path='/python' element={<Python />} />
            <Route path='/node' element={<Node />} />
            <Route path='/angular' element={<Angular />} />
            <Route path='/reactjs' element={<Reactjs />} />
            <Route path='/vue' element={<Vue />} />
            <Route path='/mean' element={<Mean />} />
            <Route path='/mern' element={<Mern />} />
            <Route path='/magento' element={<Magento />} />
            <Route path='/commerce' element={<WooCommerce />} />
            <Route path='/shopify' element={<Shopify />} />
            <Route path='/ios' element={<IOS />} />
            <Route path='/android' element={<Android />} />
            <Route path='/reactnative' element={<ReactNative />} />
            <Route path='/hybrid' element={<Hybrid />} />
            <Route path='/flutter' element={<Flutter />} />
            <Route path='/ionic' element={<Ionic />} />
            <Route path='/swift' element={<Swift />} />
            <Route path='/worldpress' element={<Worldpress />} />
            <Route path='/drupal' element={<Drupal />} />
            <Route path='/unreal-engine' element={<CustomUnrealEngine />} />
            <Route path='/unity' element={<UnityAppDevelopment />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/project1' element={<Project1 />} />
            <Route path='/project2' element={<Project2 />} />
            <Route path='/technology' element={<Technology />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/term-and-condition' element={<Terms />} />
            <Route path='/ai-machine-learning' element={<Ai />} />
            <Route path='/immersive_room' element={<Immersive />} />
            <Route path='/digitial_twinning' element={<Digitialtwinning />} />
            <Route path='/dino-world' element={<DinoWorld />} />
            <Route path='/meeting' element={<Message />} />
            <Route path='/e-learning' element={<ELearning />} />
            <Route path='/generative-ai' element={<AIDevelopment />} />
            <Route path='/platform-development' element={<Platform />} />
            <Route path='/immersive-rooms' element={<ImmersiveRoom />} />
            <Route path='/blogs/:slug' element={<Blogs />} />
            <Route path='/events' element={<Event />} />
            <Route path='/insightxr' element={<InsightXR />} />
            <Route path='/examwizz' element={<Examwizz />} />
            <Route path='/case-study/ai-chat-bot' element={<Casestudy />} />
            <Route path='/ImmersiveCase-Study' element={<ImmCase />} />
            <Route path='/AllCase-Studies' element={<AllCasePage />} />
            <Route path='/NHS-Case-study' element={<NhsCase />} />
            <Route path='/careers' element={<JobsMxr />} />
            <Route path='/job-description/:slug' element={<JobDetail />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
