// import React from 'react';
// import { Row, Col, Container } from 'react-bootstrap-v5';
// import video from '../../assets/videos/stats-sec.m4v';
// import { Link } from 'react-router-dom';
// import './jobs.css';
// function Ambition() {
//   return (
//     <div className=' mxr__section '>
//       <Container>
//         <Row>
//           <Col md={6} sm={12}>
//             <video
//               controls
//               autoPlay
//               muted
//               loop
//               playsInline
//               preload='auto'
//               className='career-video'
//               src={video}
//               alt='video'
//             />
//           </Col>
//           <Col md={6} sm={12}>
//             <h3 className='mxr__section-title textUppercase '>
//               Work With Purpose, Learn With Passion
//             </h3>
//             <p className=''>
//               As an ambitious, creative and growing organisation, we believe
//               being at work should be a positive and fun experience and we work
//               as a team to make this possible.
//               <br /> We’re committed to research and learning and we actively
//               encourage curiosity as we understand this is what gives us the
//               competitive edge and allows our team be authentically themselves.
//             </p>
//             <button className='mxr__section-btn careerWhatwe'>
//               {' '}
//               <Link to={'/'} className='text-white'>
//                 {' '}
//                 WHAT WE DO{' '}
//               </Link>
//               &rarr;{' '}
//             </button>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Ambition;

import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import video from '../../assets/videos/stats-sec.m4v';
import { Link } from 'react-router-dom';
import './jobs.css';

function Ambition() {
  return (
    <div className='mxr__section'>
      <Container>
        <Row className='align-items-center'>
          <Col md={6} sm={12} className='mb-4 mb-md-0'>
            <video
              controls
              autoPlay
              muted
              loop
              playsInline
              preload='auto'
              className='career-video w-100'
              src={video}
              alt='video'
            />
          </Col>
          <Col md={6} sm={12}>
            <h3 className='mxr__section-title text-uppercase mb-3'>
              Work With Purpose, Learn With Passion
            </h3>
            <p className='mb-4'>
              As an ambitious, creative and growing organisation, we believe
              being at work should be a positive and fun experience and we work
              as a team to make this possible.
              <br /> We’re committed to research and learning and we actively
              encourage curiosity as we understand this is what gives us the
              competitive edge and allows our team be authentically themselves.
            </p>
            <button className='mxr__section-btn careerWhatwe'>
              <Link to={'/'} className='text-white'>
                WHAT WE DO
              </Link>
              &rarr;
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Ambition;
