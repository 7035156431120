import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { FaBook, FaGraduationCap, FaIndustry } from 'react-icons/fa';
import { FaBagShopping } from 'react-icons/fa6';

const Talent = () => {
  return (
    <div className='mxr__capabilities mxr__section white_bg_point'>
      <Container>
        <h3
          className='textUppercase mxr__section-title '
          style={{ textAlign: 'center' }}
        >
          Our Emerging Talent Opportunities
        </h3>
        <Row className='mt-5'>
          <Col md={6} sm={12} className='bottomMargin'>
            <div className='icon_leftside'>
              <FaBagShopping size={30} color='#1292ee' />
            </div>
            <div>
              <h5>Work Experience</h5>
              <p>
                Work experience refers to the practical knowledge, skills, and
                expertise gained by an individual through their employment or
                involvement in various professional activities. It encompasses
                the hands-on application of theoretical concepts learned in
                educational settings and includes the tasks, responsibilities,
                and challenges encountered in a work environment.
              </p>
            </div>
          </Col>
          <Col md={6} sm={12} className='bottomMargin'>
            <div className='icon_leftside'>
              <FaBook size={30} color='#1292ee' />
            </div>
            <div>
              <h5>Apprenticeship</h5>
              <p>
                An apprenticeship is a formal arrangement in which an
                individual, known as an apprentice, undergoes a period of
                training and education in a specific trade, craft, or profession
                under the guidance and supervision of a skilled mentor or
                experienced professional. The purpose of an apprenticeship is to
                provide hands-on experience and practical knowledge to the
                apprentice, enabling them to acquire the skills and competencies
                required for their chosen occupation.
              </p>
            </div>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col md={6} sm={12} className='bottomMargin motionMargin'>
            <div className='icon_leftside'>
              <FaIndustry size={30} color='#1292ee' />
            </div>
            <div>
              <h5>Industrial Placement</h5>
              <p>
                Industrial placements, often referred to as student placements
                or internships, are structured work experiences that students
                undergo as part of their education and training. These
                placements typically occur in a professional setting related to
                the student's field of study, providing practical, hands-on
                experience in the real-world application of academic knowledge.
              </p>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
              <FaGraduationCap size={30} color='#1292ee' />
            </div>
            <div>
              <h5>Graduate Placement</h5>
              <p>
                Graduate placements, also known as graduate internships or
                entry-level placements, are structured work experiences designed
                for recent graduates. These placements provide new graduates
                with the opportunity to apply their academic knowledge in a
                professional setting, gain practical skills, and ease the
                transition from education to the workforce.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Talent;
