// import React from 'react';

// import { Row, Col, Container, Card } from 'react-bootstrap-v5';
// import { Link } from 'react-router-dom';
// import './jobs.css';

// import jobData from './jobs.json';

// function JoinOur() {
//   return (
//     <div style={{ backgroundColor: '#f8faff' }} className='mxr__section '>
//       <Container>
//         <h3 className='mxr__section-title textUppercase text-center'>
//           Join Our Team
//         </h3>
//         <Row>
//           {jobData.map((job) => (
//             <Col md={4} key={job.id}>
//               <Card className='joinOurCards text-center'>
//                 <Card.Body>
//                   <Card.Title className='job-title'>{job.title}</Card.Title>
//                   <img
//                     src={require(`../../assets/icons/careerIcons/${job.img}`)}
//                     alt={job.title}
//                     className='job-image'
//                   />
//                   <Card.Text>
//                     {' '}
//                     <strong>Job Type:</strong> {job.type}
//                   </Card.Text>
//                   <Card.Text>
//                     {' '}
//                     <strong>Job Location:</strong> {job.location}
//                   </Card.Text>
//                   <Card.Text>{job.shortdesc}</Card.Text>
//                   <Link
//                     to={`/job-description/${job.slug}`}
//                     className='read-more'
//                   >
//                     Read More
//                   </Link>
//                 </Card.Body>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default JoinOur;


import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import './jobs.css';
import jobData from './jobs.json';

function JoinOur() {
  return (
    <div style={{ backgroundColor: '#f8faff' }} className='mxr__section'>
      <Container>
        <h3 className='mxr__section-title text-uppercase text-center mb-4'>
          Join Our Team
        </h3>
        <Row>
          {jobData.map((job) => (
            <Col md={6} lg={4} sm={12} key={job.id} className='mb-4'>
              <Card className='joinOurCards text-center shadow-sm h-100'>
                <Card.Body>
                  <Card.Title className='job-title mb-3'>{job.title}</Card.Title>
                  <img
                    src={require(`../../assets/icons/careerIcons/${job.img}`)}
                    alt={job.title}
                    className='job-image mb-3'
                  />
                  <Card.Text>
                    <strong>Job Type:</strong> {job.type}
                  </Card.Text>
                  <Card.Text>
                    <strong>Job Location:</strong> {job.location}
                  </Card.Text>
                  <Card.Text className='job-shortdesc mb-3'>
                    {job.shortdesc}
                  </Card.Text>
                  <Link
                    to={`/job-description/${job.slug}`}
                    className='read-more '
                  >
                    Read More
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default JoinOur;

