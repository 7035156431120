import React from 'react';
import Readytotalk from '../../components/readytotalk/Readytotalk';
import Innerbanner from '../../components/innerbanner/Innerbanner';
import { Helmet } from 'react-helmet';
import Ambition from '../../components/JobsCompo/Ambition';
// import video from '../../assets/videos/video2.mp4';
import JoinOur from '../../components/JobsCompo/JoinTeam';
import Talent from '../../components/JobsCompo/Talent';

const bannerdata = {
  img: 'career-job.jpg',
  title: 'Discover Your Future with Our Exciting Career Opportunities',
  text: 'Be part of a dynamic team where your skills and passion drive innovation and success',
};

const readytotalk = {
  img: 'about-banner.jpg',
  title: 'Get in Touch',
  text: 'Ready to innovate and create? Partner with MXR today to explore how our cutting-edge solutions can transform your business or educational institution.',
  button: 'Contact',
};
const JobsMxr = () => {
  return (
    <div>
      <Helmet>
        <title>Careers | MXR </title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>

      <Innerbanner banner={bannerdata} />
      <Ambition />
      <JoinOur />
      <Talent />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
};

export default JobsMxr;
