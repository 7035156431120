import React from 'react';
import { useParams } from 'react-router-dom';
import jobData from './jobs.json';
import { Container, Row, Col } from 'react-bootstrap-v5';
import Innerbanner from '../../components/innerbanner/Innerbanner';
import { Helmet } from 'react-helmet';
import './jobs.css';
const bannerdata = {
  img: 'Career-page.jpg',
  title: 'Your Next Career Step Awaits!',
  text: 'Discover exciting opportunities, explore the role, and find out how you can contribute to our team. Let’s shape the future together!',
};
const JobDetail = () => {
  const { slug } = useParams();

  const job = jobData.find((job) => job.slug === slug);

  if (!job) {
    return <h2>Job Not Found</h2>;
  }

  return (
    <>
      <Helmet>
        <title>Job Deatils | MXR </title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>

      <Innerbanner banner={bannerdata} />
      <Container className='jobDetailMargin'>
        <Row className="align-items-start" >
        <Col
            md={4}
  style={{
    position:'sticky',
    top: '100px',
    height: 'fit-content',
  }}
  className="job-summary"
>  
            {/* Header Title */}
            <h2
              
              style={{
                color: '#1292ee',
                marginTop: '50px',
                width: '100%',
              }}
            >
              {job.title}
            </h2>
            <div
            className='borderBottom'
            style={{
                color: '#1292ee',
                borderBottom: '1px solid #1292ee',
                width: '310%',
              }}>
            </div>
           

            {/* Summary Section */}
            <section style={{ marginBottom: '20px', marginTop: '35px' }}>
              <h4>Summary</h4>
              <p>
                <strong>Type:</strong> {job.type}
              </p>
              <p>
                <strong>Location:</strong> {job.location}
              </p>
              <p>
                <strong>Reports to:</strong> {job.reports_to}
              </p>
              <p>
                <strong>{job.salary_title && job.salary_title}</strong>{' '}
                {job.salary && job.salary}
              </p>
              <p>
                <strong>DBS Check:</strong> {job.dbs_check}
              </p>
            </section>

            {/* How to Apply Section */}
            <section style={{ marginBottom: '20px' }}>
              <h4>{job.how_to_apply.title}</h4>
              <p>
                {job.how_to_apply.instructions}
                <a
                  href={`mailto:${job.how_to_apply.email}`}
                  style={{ color: '#1292ee', fontWeight: 'bold' }}
                >
                  {job.how_to_apply.email}
                </a>
              </p>
            </section>
          </Col>
          <Col
            style={{
              marginTop: '160px',
              backgroundColor: '#ffffff',
              zIndex: 100,
            }}
          >
            {/* About Company */}
            <section style={{ marginBottom: '20px' }}>
              <h4>{job.company.about_title}</h4>
              <p dangerouslySetInnerHTML={{ __html: job.company.about }}></p>
            </section>

            {/* Role Overview */}
            <section>
              <h4>{job.role_overview.title}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: job.role_overview.description,
                }}
              ></p>
            </section>

            <section style={{ marginBottom: '20px' }} className='unOrderedList'>
              <h4>{job.key_responsibilities.title}</h4>
              <ul style={{ paddingLeft: '20px' }}>
                {job.key_responsibilities.items?.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </section>
            <section style={{ marginBottom: '20px' }} className='unOrderedList'>
              <h4>
                {job.Ideal_Candidate_Profile &&
                  job.Ideal_Candidate_Profile.title}
              </h4>
              <p>
                {' '}
                {job.Ideal_Candidate_Profile &&
                  job.Ideal_Candidate_Profile.description}
              </p>{' '}
              <ul style={{ paddingLeft: '20px' }}>
                {job.Ideal_Candidate_Profile &&
                  job.Ideal_Candidate_Profile.items?.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
              </ul>
            </section>
            <section style={{ marginBottom: '20px' }} className='unOrderedList'>
              <h4>{job.essential_skills.title}</h4>
              <ul style={{ paddingLeft: '20px' }}>
                {job.essential_skills.essential?.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </section>
            <section style={{ marginBottom: '20px' }} className='unOrderedList'>
              <h4>{job.desirable_skills && job.desirable_skills.title}</h4>
              <ul style={{ paddingLeft: '20px' }}>
                {job.desirable_skills &&
                  job.desirable_skills.description?.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
              </ul>
            </section>

            <section style={{ marginBottom: '20px' }} className='unOrderedList'>
              <h4>
                {job.personal_attributes && job.personal_attributes.title}
              </h4>
              <ul style={{ paddingLeft: '20px' }}>
                {job.personal_attributes &&
                  job.personal_attributes.description?.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
              </ul>
            </section>

            <section className='unOrderedList'>
              <h4>{job.why_join.title}</h4>
              <p>{job.why_join.description}</p>

              <ul style={{ paddingLeft: '20px' }}>
                {job.why_join.work_environment.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
              <h4>{job.why_join.other_benefits_title}</h4>
              <ul style={{ paddingLeft: '20px' }}>
                {job.why_join.financial_and_health.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </section>
            <section>
              <h4>{job.Our_Commitment}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: job.diversity_commitment,
                }}
              ></p>
            </section>

            <p>{job.recruitment_process}</p>

            <section>
              <h4>{job.how_to_apply.title}</h4>
              <p
                dangerouslySetInnerHTML={{ __html: job.application_email }}
              ></p>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JobDetail;



